<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->
							<article class="article-body" id="article-body" rel="image-enlarge">
								<p data-identifyelement="463" dir="ltr"><strong data-identifyelement="471">Links updated October 2021</strong></p>
								<p data-identifyelement="463" dir="ltr"><br data-identifyelement="464" /></p>
								<p data-identifyelement="465">
									Insert the required m3u and replace <strong data-identifyelement="466">YOUR_</strong
									><strong data-identifyelement="467">EMAIL/ PASSWORD</strong> with your details<br data-identifyelement="468" /><br data-identifyelement="469" />
								</p>
								<p data-identifyelement="470">
									<strong data-identifyelement="471">-Live TV Channels</strong><br data-identifyelement="472" /><a
										data-ft='{"tn":"-U"}'
										data-identifyelement="473"
										data-lynx-mode="asynclazy"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										target="_blank"
									></a
									><a
										data-ft='{"tn":"-U"}'
										data-identifyelement="472"
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/<strong data-identifyelement="474"><span data-identifyelement="475" style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
									>/<strong data-identifyelement="476"><span data-identifyelement="477" style="color: rgb(0, 0, 0)">PASSWORD</span></strong
									><strong data-identifyelement="478"><span data-identifyelement="479" style="color: rgb(0, 0, 0)">/</span></strong
									>m3u8/<strong data-identifyelement="473">livetv</strong>
								</p>
								<p data-identifyelement="480">
									<br data-identifyelement="481" /><strong data-identifyelement="482">-Movies&nbsp;</strong><br data-identifyelement="483" /><a
										data-identifyelement="484"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/movies`"
										rel="noreferrer noopener"
									></a
									><a
										data-ft='{"tn":"-U"}'
										data-identifyelement="474"
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/<strong data-identifyelement="485"><span data-identifyelement="486" style="color: rgb(0, 0, 0)">YOUR_EMAIL</span></strong
									>/<strong data-identifyelement="487"><span data-identifyelement="488" style="color: rgb(0, 0, 0)">PASSWORD</span></strong
									>/m3u8/<strong data-identifyelement="475">movies</strong>
								</p>
								<p data-identifyelement="480"><strong data-identifyelement="476">-Sports VOD (events)</strong></p>
								<p data-identifyelement="480">
									<strong data-identifyelement="477"
										><a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/events`" data-identifyelement="478"></a></strong
									><a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/events`" data-identifyelement="479"></a
									><a data-identifyelement="480" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/events`"></a
									><a
										data-ft='{"tn":"-U"}'
										data-identifyelement="481"
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/events<a
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/events`"
										data-identifyelement="482"
									></a
									><strong data-identifyelement="483"
										><a :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/events`" data-identifyelement="484"></a></strong
									><br data-identifyelement="489" /><br data-identifyelement="490" /><strong data-identifyelement="491"
										>-TV Shows (VERY big list. Don't use on old/small devices)</strong
									>
								</p>
								<p data-identifyelement="480">
									<strong data-identifyelement="491" dir="ltr">Each link must be added individually. &nbsp;Add ALL links for full catalog</strong
									><br data-identifyelement="492" /><a
										data-fr-linked="true"
										data-identifyelement="493"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/1`"
									></a
									><a
										data-ft='{"tn":"-U"}'
										data-identifyelement="485"
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/<strong data-identifyelement="486">tvshows</strong>/<strong data-identifyelement="487">1</strong> (Most new
									tv shows)
								</p>
								<p data-identifyelement="494">
									<a data-fr-linked="true" data-identifyelement="495" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/2`"></a
									><a
										data-ft='{"tn":"-U"}'
										data-identifyelement="488"
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/<strong data-identifyelement="489">2</strong>
								</p>
								<p data-identifyelement="496">
									<a data-fr-linked="true" data-identifyelement="497" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/3`"></a
									><a
										data-ft='{"tn":"-U"}'
										data-identifyelement="490"
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/<strong data-identifyelement="491">3</strong>
								</p>
								<p data-identifyelement="498">
									<a data-fr-linked="true" data-identifyelement="499" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/4`"></a
									><a
										data-ft='{"tn":"-U"}'
										data-identifyelement="492"
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/<strong data-identifyelement="493">4</strong>
								</p>
								<p data-identifyelement="498" dir="ltr">
									<a data-fr-linked="true" data-identifyelement="494" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/5`"
										>{{ $store.state.brandInfo.tvLink }}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/<strong data-identifyelement="495">5</strong></a
									>
								</p>
								<p data-identifyelement="498" dir="ltr">
									<a data-fr-linked="true" data-identifyelement="496" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows`"></a
									><a
										data-ft='{"tn":"-U"}'
										data-identifyelement="497"
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/<strong data-identifyelement="498">6</strong>
								</p>
								<p data-identifyelement="498" dir="ltr">
									<a data-fr-linked="true" data-identifyelement="499" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows`"></a
									><a
										data-ft='{"tn":"-U"}'
										data-identifyelement="500"
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/<strong data-identifyelement="501">7</strong>
								</p>
								<p data-identifyelement="498" dir="ltr">
									<a data-fr-linked="true" data-identifyelement="502" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows`"></a
									><a
										data-ft='{"tn":"-U"}'
										data-identifyelement="503"
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/<strong data-identifyelement="504">8</strong>
								</p>
								<p data-identifyelement="498" dir="ltr">
									<a data-fr-linked="true" data-identifyelement="505" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows`"></a
									><a
										data-ft='{"tn":"-U"}'
										data-identifyelement="506"
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/<strong data-identifyelement="507">9</strong>
								</p>
								<p data-identifyelement="498" dir="ltr">
									<a data-fr-linked="true" data-identifyelement="508" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows`"></a
									><a
										data-ft='{"tn":"-U"}'
										data-identifyelement="509"
										data-lynx-mode="asynclazy"
										dir="ltr"
										:href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/livetv`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; color: rgb(8, 18, 29)"
										target="_blank"
									>
										{{ $store.state.brandInfo.tvLink }}</a
									>/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/<strong data-identifyelement="510" dir="ltr">...</strong>
								</p>
								<p data-identifyelement="498" dir="ltr">
									<a data-fr-linked="true" data-identifyelement="501" :href="`${$store.state.brandInfo.tvLink}/api/list/YOUR_EMAIL/PASSWORD/m3u8/tvshows/5`"></a>
								</p>
								<p data-identifyelement="498">(Oldest tv shows)</p>
								<p data-identifyelement="500" dir="ltr">*The playlist <strong data-identifyelement="511">NUMBER</strong> goes up to 20 currently.</p>
								<p data-identifyelement="515"><br data-identifyelement="516" /></p>
								<p data-identifyelement="517"><br data-identifyelement="518" /></p>
								<p data-identifyelement="519">After click ADD EPG and insert your required EPG (only one)</p>
								<p data-identifyelement="520"><br data-identifyelement="521" /></p>
								<p data-identifyelement="522">
									<strong data-identifyelement="523">-TV Guide/EPG URL</strong><br data-identifyelement="524" />Select Lite or Full url.
								</p>
								<p data-identifyelement="525"><br data-identifyelement="526" /></p>
								<p
									data-identifyelement="527"
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(0, 0, 0);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									Regular version – 7 day listing&nbsp;
								</p>
								<p
									data-identifyelement="528"
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(0, 0, 0);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<a
										:href="$store.state.brandInfo.epgLink"
										rel="noreferrer noopener"
										target="_top"
										style="box-sizing: border-box; background-color: transparent; color: rgb(44, 92, 197); text-decoration: none; user-select: auto"
										data-identifyelement="529"
									></a
									><a
										:href="`${$store.state.brandInfo.tvLink}/api/epg/YOUR_EMAIL/PASSWORD/full.xml.gz`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; background-color: transparent; color: rgb(44, 92, 197); text-decoration: none; user-select: auto"
										data-identifyelement="530"
									></a
									><a
										data-fr-linked="true"
										:href="$store.state.brandInfo.tvGuidLink"
										style="box-sizing: border-box; background-color: transparent; color: rgb(44, 92, 197); text-decoration: none; user-select: auto"
										data-identifyelement="531"
									></a
									><a
										data-identifyelement="532"
										:href="$store.state.brandInfo.tvGuidLink"
										style="box-sizing: border-box; background-color: transparent; color: rgb(44, 92, 197); text-decoration: none; user-select: auto"
									></a
									><a :href="$store.state.brandInfo.tvGuidLink">{{ $store.state.brandInfo.tvGuidLink }}</a>
								</p>
								<p
									data-identifyelement="533"
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(0, 0, 0);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<br style="box-sizing: border-box" data-identifyelement="534" />
								</p>
								<p
									data-identifyelement="535"
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(0, 0, 0);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									Lite version - 48h listing (For old/small devices)
								</p>
								<p
									data-identifyelement="536"
									dir="ltr"
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(0, 0, 0);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<a
										:href="$store.state.brandInfo.epgLink"
										rel="noreferrer noopener"
										target="_top"
										style="box-sizing: border-box; background-color: transparent; color: rgb(44, 92, 197); text-decoration: none; user-select: auto"
										data-identifyelement="537"
									></a
									><a
										:href="`${$store.state.brandInfo.tvLink}/api/epg/YOUR_EMAIL/PASSWORD/lite.xml.gz`"
										rel="noreferrer noopener"
										style="box-sizing: border-box; background-color: transparent; color: rgb(44, 92, 197); text-decoration: none; user-select: auto"
										data-identifyelement="538"
									></a
									><a
										href="$store.state.brandInfo.tvGuidLiteLink"
										style="box-sizing: border-box; background-color: transparent; color: rgb(44, 92, 197); text-decoration: none; user-select: auto"
										data-identifyelement="539"
									></a
									><a
										data-identifyelement="540"
										dir="ltr"
										:href="$store.state.brandInfo.tvGuidLink"
										style="box-sizing: border-box; background-color: transparent; color: rgb(44, 92, 197); text-decoration: none; user-select: auto"
									></a
									><a :href="$store.state.brandInfo.tvGuidLink">{{ $store.state.brandInfo.tvGuidLink }}</a>
								</p>
								<p
									data-identifyelement="536"
									dir="ltr"
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(0, 0, 0);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<br data-identifyelement="512" />
								</p>
								<p
									data-identifyelement="545"
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(0, 0, 0);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									-For <strong data-identifyelement="477">Tivimate</strong>/<strong data-identifyelement="478">Smarters&nbsp;</strong>you might need to use
									instead of the abvove:
								</p>
								<p
									data-identifyelement="546"
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(0, 0, 0);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<a data-identifyelement="547" :href="$store.state.brandInfo.tvGuidLiteLink">{{ $store.state.brandInfo.tvGuidLiteLink }} </a>
								</p>
								<p data-identifyelement="548"><br data-identifyelement="549" /></p>
							</article>

							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'whatAreTheM3UEpgUrlsSportsVodUrlIncluded',
	data() {
		return {
			title: this.$route.meta.title
		};
	}
};
</script>

<style scoped>
p{
	width: 90%;
}

</style>
